@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.markdown-body {
  @apply text-gray-800;
}

.markdown-body h1 {
  @apply text-3xl font-bold mb-4 mt-6;
}

.markdown-body h2 {
  @apply text-2xl font-bold mb-3 mt-5;
}

.markdown-body h3 {
  @apply text-xl font-bold mb-2 mt-4;
}

.markdown-body p {
  @apply mb-4;
}

.markdown-body ul {
  @apply list-disc ml-6 mb-4;
}

.markdown-body ol {
  @apply list-decimal ml-6 mb-4;
}

.markdown-body a {
  @apply text-blue-600 hover:text-blue-800;
}

.markdown-body blockquote {
  @apply border-l-4 border-gray-200 pl-4 italic my-4;
}

.markdown-body code {
  @apply bg-gray-100 rounded px-1;
}

.markdown-body pre {
  @apply bg-gray-100 rounded p-4 mb-4 overflow-x-auto;
}
